.footer {
  background-color: #a0a0a0;
  padding: 8px;
  height: 11vh;
  position: fixed;
  width: 100%;
  bottom: 0;
  text-align: center;
}

.App-link {
  color: #61dafb;
}

footer-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
